.dongmi{
    width: 100%;
    height: 100vh;
    background: url('https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/earth.png') no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    .contentMain{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content{
        display: flex;
        .left{
            .title{
                font-weight: bold;
                color: #FFFFFF;
                font-size: 70px;
                line-height: 70px;
                background: linear-gradient(180deg, #FFFFFF 0%, #79D4FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .color{
                font-weight: bold;
                color: #58D9FF;
                font-size: 82px;
                margin-top: 20px;
                line-height: 82px;
            }
            .img{
                display: flex;
                margin-top: 239px;
                >div{
                    width: 168px;
                    height: 168px;
                    padding: 3px;
                    overflow: hidden;
                    background: url("../../assets/banner/icon_biankuang.svg") no-repeat center center;
                    z-index: 10;
                    img{
                        width: 162px;
                    }
                    >div{
                        border-radius: 50%;
                        width: 162px;
                        height: 162px;
                        overflow: hidden;
                    }
                }
                >div:nth-child(2){
                    position: relative;
                    left: -15px;
                }
                >div:nth-child(3){
                    position: relative;
                    left: -30px;
                }
            }
        }
        .right{
            width: 430px;
            height: 624px;
            margin-left: 92px;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 40px 36px 35px;
            position: relative;
            .loge{
                text-align: center;
            }
            .tip{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 24px;
                div{
                    width: 96px;
                    height: 2px;
                    background: linear-gradient(90deg, #C9CDD4 0%, #F6FAFF 100%);
                    border-radius: 3px;
                }
                div:nth-child(1){
                    background: linear-gradient(90deg, #F6FAFF 0%, #C9CDD4 100%)
                }
                span{
                    margin: 0 16px;
                    font-size: 16px;
                    color: #1D2129;
                    line-height: 22px;
                }
            }
        }
    }
    .footer{
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        line-height: 22px;
        width: 100%;
        img{
            margin-right: 4px;
        }
        span{
            display: inline-flex;
            align-items: center;
            margin:8px 10px;
        }
        span:nth-child(1){
            margin-left: 0;
        }
        a{
            color: #fff;
        }
        a:hover {
            color: #40a9ff;
        }
    }
}

