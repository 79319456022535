.phoneLogin{
    width: 100%;
    height: 100vh;
    background: url('https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/earth.png') no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    padding-top: 25px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
        padding: 0 16px 0 44px;
        height: 46px;
        border-radius: 8px;
        border: 1px solid #E5E6E8;
    }
    // .ant-select-selection-item{
    //     color: #1D2129;
    // }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item{
        line-height: 44px;
        color: #1D2129;
        font-weight: bold;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        box-shadow: none;
    }
    .ant-select-arrow{
        width: 21px;
        height: 21px;
        top: 45%;
    }
    .login-form-forgot {
        float: right;
    }
    .ant-btn-lg{
        height: 44px;
        background: #2EA7E0;
        border-radius: 8px;
        border-color: #2EA7E0;
        color: #C9CDD4;
        span{
            color: #fff;
            font-size: 16px;
        }
    }
    .login-form-button {
        width: 100%;
        background: linear-gradient(90deg, #002F81 0%, #2EA7E0 100%);
        border-radius: 8px;
        height: 46px;
    }
    .selectType{
        position: relative;
        >img{
            position: absolute;
            z-index: 10;
            left: 20px;
            top: 14px;
        }
    }
    .site-form-item-icon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-modal-close-x {
        line-height: 36px;
        width: 36px;
        height: 36px;
    }
    .login-wrapper {
        position: relative;
        .img-type {
            position: absolute;
            right: 6px;
            top: 6px;
            z-index: 10;
            cursor: pointer;
        }
    }
    .arcode {
        margin-left: auto;
        padding: 45px 36px 46px;
        .failure {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            p {
                width: 100%;
                text-align: center;
                color: rgba(0, 0, 0, 0.85);
            }
            p:nth-child(2) {
                color: #fff;
                width: 60px;
                height: 24px;
                margin-top: 8px auto 0;
                background: #1ec162;
                border-radius: 4px;
                display: flex;
                font-size: 12px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i {
                    margin-right: 3px;
                }
            }
            > div {
                height: 212px;
                width: 212px;
                border: 1px solid #d9d9d9;
                padding: 9px;
                position: relative;
                img {
                    width: 188px;
                    height: 188px;
                    left: 9px;
                    top: 9px;
                    position: absolute;
                }
                div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    background: rgba(255, 255, 255, 0.85);
                    position: relative;
                    z-index: 2;
                }
            }
        }

        .saoma {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            >div:nth-last-child(1){
                border: 1px solid #D9D9D9;
            }
            img {
                width: 160px;
            }
        }
    }
    .ant-tabs-nav-list {
        width: 100%;
        margin-top: 10px;
        .ant-tabs-tab {
            width: 50%;
            margin: 0;
            padding: 12px 0;
            .ant-tabs-tab-btn {
                width: 100%;
                text-align: center;
                color: #1D2129;
                line-height: 25px;
                font-size: 18px;
            }
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn{
            color: #2EA7E0;
            font-weight: bold;
        }
        .ant-tabs-tab-active{
            font-weight: bold;
        }
        .ant-tabs-ink-bar{
            background:  #2EA7E0;
            border-left: 30px solid #fff;
            border-right: 30px solid #fff;
        }
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0;
    }
    .ant-tabs-top > .ant-tabs-nav::before{
        border-bottom: none;
    }
    .forget {
        padding: 24px 30px 46px;
        .forget-back {
            margin-bottom: 34px;
            cursor: pointer;
            font-size: 14px;
        }

        .forget-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 26px;
        }
    }
    .tryuse-content{
        margin-top: 24px;
    }
    .tryuse-desc {
        font-size: 14px;
        color: #FFFFFF;
        margin-top: -10px;
        text-align: center;
    }
    .tryuse-title {
        position: absolute;
        top: 24px;
        left: 24px;
        display: flex;
        align-items: center;
        div:nth-child(2){
            font-size: 16px;
            font-weight: 500;
            color: #4E5969;
            line-height: 24px;
            margin-left: 20px;
        }
        .tryuse-back{
            cursor: pointer;
            span{
                margin-right: 12px;
            }
            height: 24px;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .tryuse-dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000;
        margin: 0 6px;
    }
    .tryuse-sub-title {
        color: #333;
        margin: 10px 0;
    }
    .tryuse-btns {
        display: flex;
        margin-bottom: 20px;
        button {
            flex: 1;
        }
    }
    .spin{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-input-lg, .ant-input-affix-wrapper-lg{
        padding: 8px 20px;
        border-radius: 8px;
        line-height: 26px;
        // color: #C9CDD4;
        color: rgba(0,0,0,.85);
    }
    .getCode{
        width: 92px;
        height: 32px;
        background: linear-gradient(90deg, #002F81 0%, #2EA7E0 100%);
        border-radius: 8px;
        color: #fff;
        padding: 0;
        text-align: center;
        position: absolute;
        top: 7px;
        right: 13px;
        z-index: 2;
    }

    .footer{
        position: absolute;
        bottom: 20px;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        color: #FFFFFF;
        line-height: 22px;
        width: 100%;
        padding-left: 4px !important;
        padding-right: 21px !important;
        img{
            margin-right: 4px;
        }
        span{
            //display: inline-flex;
            align-items: center;
            margin:8px 10px;
        }
        span:nth-child(1){
            margin-left: 0;
        }
        a{
            color: #fff;
        }
        a:hover {
            color: #40a9ff;
        }
    }
}

.tips{
    margin-top: 16px;
    height: 32px;
    background: rgba(46,167,224,0.1);
    border-radius: 4px;
    border: 1px solid #2EA7E0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 16px;
    margin-bottom: 16px;
    div:nth-child(1){
        display: flex;
        align-items: center;
        color: #2EA7E0;
        font-size: 14px;
        img{
            margin-right: 6px;
        }
    }
    div:nth-child(2){
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #2EA7E0;
        font-size: 14px;
    }
}
.modal{

    .sToWho, .sContent{
        color: #1D2129;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 18px;
    }
    .sContent{
        text-indent: 2rem;
        margin-bottom: 30px;
    }
    .sPublisher, .sPublishTime{
        color: #1D2129;
        line-height: 20px;
        text-align: right;
    }
    .sPublishTime{
        margin-top: 4px;
    }
}
.code_tip{
    display: flex;
    align-items: center;
    justify-content: center;
}


.title{
    font-weight: bold;
    color: #FFFFFF;
    font-size: 40px;
    padding-top: 10px;
    line-height: 45px;
    text-align: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #79D4FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.color{
    font-weight: bold;
    color: #58D9FF;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 45px;
    text-align: center;
}

